import React, { useState, useEffect } from 'react';
import Navbar from "../components/common/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons'

const feeData = {
  Fixed: {
    "Agreya Momentum": { managementFee: 2.0, performanceFee: 0, hurdleRate: "NA" },
    "Agreya Total Return": { managementFee: 2.5, performanceFee: 0, hurdleRate: "NA" },
    "Agreya Multi-Asset": { managementFee: 0.5, performanceFee: 0, hurdleRate: "NA" },
    "Agreya Multi-Asset Enhancer": { managementFee: 1.2, performanceFee: 0, hurdleRate: "NA" },
    "Agreya Total Return Enhancer": { managementFee: 2.5, performanceFee: 0, hurdleRate: "NA" },
    "Agreya Momentum Enhancer": { managementFee: 2.0, performanceFee: 0, hurdleRate: "NA" }
  },
  Hybrid: {
    "Agreya Momentum": { managementFee: 1.2, performanceFee: 15, hurdleRate: "10%" },
    "Agreya Total Return": { managementFee: 1.2, performanceFee: 15, hurdleRate: "10%" },
    "Agreya Total Return Enhancer": { managementFee: 1.2, performanceFee: 20, hurdleRate: "12%" },
    "Agreya Momentum Enhancer": { managementFee: 1.2, performanceFee: 20, hurdleRate: "12%" },
    "Portfolio Return Optimiser": { managementFee: 0.2, performanceFee: 20, hurdleRate: "0%" }
  }
};

function FeeSelection() {
  const [investmentAmount, setInvestmentAmount] = useState('5000000'); 
  const [formattedAmount, setFormattedAmount] = useState('50 lakhs'); 
  const [errorMessage, setErrorMessage] = useState('');
  const [showTable, setShowTable] = useState(true); 
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isSubmitted, setIsSubmitted] = useState(false); 

  useEffect(() => {
    const storedAmount = localStorage.getItem('investmentAmount');
    if (storedAmount) {
      setInvestmentAmount(storedAmount); 
      setFormattedAmount(formatAmount(storedAmount)); 
      setIsSubmitted(true); 

    }
    else{
      setIsSubmitted(true);
    }
  }, []);

  const handleInvestmentChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { 
    setInvestmentAmount(value);
    }
    localStorage.setItem('investmentAmount', value); 
    setFormattedAmount(formatAmount(value)); 
    setIsSubmitted(false);
    setShowTable(true);  
  };

  const handleSubmit = () => {
    const investmentValue = parseFloat(investmentAmount);
    setIsSubmitting(true); 

   
    if (!investmentAmount || isNaN(investmentValue) || investmentValue < 5000000) {
      setErrorMessage('Kindly enter an amount above 50 lakhs.');
      setShowTable(true); 
      return;
    }
    setErrorMessage('');
    setShowTable(true);  
    setIsSubmitted(true);
    setTimeout(() => setIsSubmitting(false), 1000);
  };

  const handleResetToDefault = () => {
    const defaultAmount = '5000000'; 
    setInvestmentAmount(defaultAmount);
    setFormattedAmount(formatAmount(defaultAmount)); 
    setErrorMessage('');
    setIsSubmitted(true);
  };
  
  const handleClick = (feeModel, approach) => {
    if (!isSubmitted) {
      setErrorMessage('Please submit the investment amount first.');
      return;
    }
    const investmentValue = parseFloat(investmentAmount);

    if (!investmentValue || isNaN(investmentValue) || investmentValue < 5000000) {
      setErrorMessage('Kindly enter an amount above 50 lakhs.');
      return;
    }

    let feeDetails;
    if (feeModel === 'MultiYearHybrid') {
      feeDetails = feeData.Hybrid[approach];
    } if (feeModel === 'MultiYearFixed') {
      feeDetails = feeData.Fixed[approach];
    } else if (feeData[feeModel]?.[approach]) {
      feeDetails = feeData[feeModel][approach];
    }

    if (!feeDetails) {
      console.error(`No fee details found for model: ${feeModel}, approach: ${approach}`);
      return;
    }

    setShowTable(true);
    history.push({
      pathname: '/fee-summary',
      state: { 
        selectedFeeModel: feeModel,
        selectedApproach: approach,
        investmentAmount: investmentValue,  
        feeDetails
      }
    });
  };

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) {
      return;
    }
    if (parsedAmount < 5000000) {
      return;
    }
    if (parsedAmount >= 10000000) {
      const formatted = parsedAmount / 10000000;
      return `${formatted} crore`;
    } else if (parsedAmount >= 100000) {
      const formatted = parsedAmount / 100000;
      return `${formatted} lakhs`;
    } else {
      return `${parsedAmount}`;  
    }
  };
  
  return (
    <main>
      <header className="header-area">
        <Navbar />
      </header>
      <div className="fee-model-container">
      <p className="fee-model-p"style={{lineHeight:'1.8'}}>
  Agreya offers the following fee models:<br/>
  &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;a) Fixed Fee Model<br/>
  &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;b) Hybrid Fee Model
</p>


            <p className='ap' style={{ textAlign:'center',marginTop:'2%'}}>The  detailed structure and fee illustrations given in the below table</p>
        {showTable && (
          <>
            <div className="fee-model">
              <table>
              <thead>
      <tr>
        <th rowSpan="2">Investment Approaches</th>
        <th colSpan="1">Fixed Fee</th>
        <th colSpan="2">Fee Illustration *</th>
        <th colSpan="3">Hybrid Fee</th>
        <th colSpan="2">Fee Illustration *</th>
      </tr>
      <tr>
        <th>Management Fee</th>
        <th>One Year</th>
        <th>Multi-Year</th>
        <th>Management Fee</th>
        <th>Performance Fee</th>
        <th>Hurdle Rate</th>
        <th>One Year</th>
        <th>Multi-Year</th>
      </tr>
    </thead>
                <tbody>
                    {Object.keys(feeData.Fixed).map((approach, index) => {
                      const fixed = feeData.Fixed[approach] || null;
                      const hybrid = feeData.Hybrid[approach] || null;
                      return (
                        <tr key={index}>
                          <td>{approach}</td>
                          <td>{fixed ? `${fixed.managementFee}% p.a.` : '—'}</td>
                          <td>
                            {fixed ? (
                              <button onClick={() => handleClick('Fixed', approach)}>
                                <FontAwesomeIcon icon={faFilePdf} style={{ color: "#ff0000", fontSize: "1.5em" }} />
                              </button>
                            ) : '—'}
                          </td>
                          <td>
                            {fixed ? (
                              <button onClick={() => handleClick('MultiYearFixed', approach)}>
                                <FontAwesomeIcon icon={faFilePdf} style={{ color: "#ff0000", fontSize: "1.5em" }} />
                              </button>
                            ) : '—'}
                          </td>
                          
                          <td>{hybrid ? `${hybrid.managementFee}% p.a.` : '—'}</td>
                          <td>{hybrid ? `${hybrid.performanceFee}%` : '—'}</td>
                          <td>{hybrid ? hybrid.hurdleRate : '—'}</td>
                          <td>
                            {hybrid ? (
                              <button onClick={() => handleClick('Hybrid', approach)}>
                                <FontAwesomeIcon icon={faFilePdf} style={{ color: "#ff0000", fontSize: "1.5em" }} />
                              </button>
                            ) : '—'}
                          </td>
                          <td>
                            {hybrid ? (
                              <button onClick={() => handleClick('MultiYearHybrid', approach)}>
                                <FontAwesomeIcon icon={faFilePdf} style={{ color: "#ff0000", fontSize: "1.5em" }} />
                              </button>
                            ) : '—'}
                          </td>
                        </tr>
                      );
                    })}

                  
                    {Object.keys(feeData.Hybrid).map((approach, index) => {
                      if (!feeData.Fixed[approach]) {
                        const hybrid = feeData.Hybrid[approach];
                        return (
                          <tr key={`hybrid-${index}`}>
                            <td>{approach}</td>
                            <td>—</td>
                            <td>—</td>
                            <td>—</td>
                            <td>{`${hybrid.managementFee}% p.a.`}</td>
                            <td>{`${hybrid.performanceFee}%`}</td>
                            <td>{hybrid.hurdleRate}</td>
                            <td>
                              <button onClick={() => handleClick('Hybrid', approach)}>
                                <FontAwesomeIcon icon={faFilePdf} style={{ color: "#ff0000", fontSize: "1.5em" }} />
                              </button>
                            </td>
                            <td>
                              <button onClick={() => handleClick('MultiYearHybrid', approach)}>
                                <FontAwesomeIcon icon={faFilePdf} style={{ color: "#ff0000", fontSize: "1.5em" }} />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>

              </table>
            
            </div>
            <p className='ap' style={{ fontStyle: 'italic',marginLeft:"-60%", }}>*For capital contribution of Rs.50 lakhs.</p>
          </>
        )}

        
        {/* Additional Information Section */}
        <div className="additional-info">

            {/* Download Fee Calculation Tool Button */}
            <button
              className="download-excel-btn"
              onClick={() => {
                const link = document.createElement('a');
                link.href ="/files/Fee _calculation_illustration.xlsx";
                link.download = 'Agreya PMS_Fee Calculation Tool.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              style={{
                marginTop: '15px',
                padding: '10px',
                backgroundColor: '#c39239',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Click here to download Fee calculation tool
            </button>
          </div>
<div className="mobile-card-view mobile-view">
 
  {/* Download Fee Calculation Tool Button */}
  <p className="fee-model-p"style={{lineHeight:'1.8'}}></p>
    <ul>
  <li style={{textAlign:'center'}}>Agreya offers the following fee models:</li>
  <li style={{textAlign:'center'}}>a) Fixed Fee Model</li>
 <li style={{textAlign:'center'}}>b) Hybrid Fee Model</li>
 </ul>


        <div className="fee-table-container">
        <table className="fee-table2">
  <thead>
    <tr>
      <th rowSpan={2}>Investment Approaches</th>
      <th>Fixed</th>
      <th colSpan={3}>Hybrid</th>
    </tr>
    <tr>
      <th>Management Fee </th>
      <th>Management Fee</th>
      <th>Performance Fee</th>
      <th>Hurdle Rate</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-label="Investment Approaches">Agreya Momentum</td>
      <td data-label="Management Fee (Fixed)">2.0% p.a.</td>
      <td data-label="Management Fee (Hybrid)">1.2% p.a.</td>
      <td data-label="Performance Fee">15%</td>
      <td data-label="Hurdle Rate">10%</td>
    </tr>
    <tr>
      <td data-label="Investment Approaches">Agreya Total Return</td>
      <td data-label="Management Fee (Fixed)">2.5% p.a.</td>
      <td data-label="Management Fee (Hybrid)">1.2% p.a.</td>
      <td data-label="Performance Fee">15%</td>
      <td data-label="Hurdle Rate">10%</td>
    </tr>
    <tr>
      <td data-label="Investment Approaches">Agreya Multi-Asset</td>
      <td data-label="Management Fee (Fixed)">0.5% p.a.</td>
      <td data-label="Management Fee (Hybrid)">—</td>
      <td data-label="Performance Fee">—</td>
      <td data-label="Hurdle Rate">—</td>
    </tr>
    <tr>
      <td data-label="Investment Approaches">Agreya Multi-Asset Enhancer</td>
      <td data-label="Management Fee (Fixed)">1.2% p.a.</td>
      <td data-label="Management Fee (Hybrid)">—</td>
      <td data-label="Performance Fee">—</td>
      <td data-label="Hurdle Rate">—</td>
    </tr>
    <tr>
      <td data-label="Investment Approaches">Agreya Total Return Enhancer</td>
      <td data-label="Management Fee (Fixed)">2.5% p.a.</td>
      <td data-label="Management Fee (Hybrid)">1.2% p.a.</td>
      <td data-label="Performance Fee">20%</td>
      <td data-label="Hurdle Rate">12%</td>
    </tr>
    <tr>
      <td data-label="Investment Approaches">Agreya Momentum Enhancer</td>
      <td data-label="Management Fee (Fixed)">2% p.a.</td>
      <td data-label="Management Fee (Hybrid)">1.2%</td>
      <td data-label="Performance Fee">20%</td>
      <td data-label="Hurdle Rate">12%</td>
    </tr>
    <tr>
      <td data-label="Investment Approaches">Agreya Portfolio Optimiser</td>
      <td data-label="Management Fee (Fixed)">—</td>
      <td data-label="Management Fee (Hybrid)">0.2% p.a.</td>
      <td data-label="Performance Fee">20%</td>
      <td data-label="Hurdle Rate">0%</td>
    </tr>
  </tbody>
</table>
<button
              className="download-excel-btn"
              onClick={() => {
                const link = document.createElement('a');
                link.href ="/files/Fee _calculation_illustration.xlsx";
                link.download = 'Agreya PMS_Fee Calculation Tool.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              style={{
                marginTop: '30%',
                padding: '10px',
                backgroundColor: '#c39239',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width:'100%'
              }}
            >
              Click here to download Fee calculation tool
            </button>
    </div>
</div>
      </div>
    </main>
  );
}
export default FeeSelection;